<template>
    <v-container id="user-profile" fluid tag="section">
        <v-row justify="center">
            <v-col cols="12">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Perfil
                        </div>

                        <!-- <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div> -->
                    </template>

                    <v-form>
                        <v-container class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="usuario.name" label="Nombre" type="text" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="usuario.password" label="Contraseña" type="text" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="usuario.password_confirmation" label="Confirmar contraseña" type="text" />
                                </v-col>

                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" class="mr-0" @click="update">
                                        Aceptar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
            </v-col>

            <!-- <v-col cols="12" md="4">
                <base-material-card class="v-card-profile" avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg">
                    <v-card-text class="text-center">
                        <h6 class="display-1 mb-1 grey--text">
                            CEO / CO-FOUNDER
                        </h6>

                        <h4 class="display-2 font-weight-light mb-3 black--text">
                            Alec Thompson
                        </h4>

                        <p class="font-weight-light grey--text">
                            Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
                        </p>

                        <v-btn color="success" rounded class="mr-0">
                            Follow
                        </v-btn>
                    </v-card-text>
                </base-material-card>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            usuario: {}
        }),
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.$http.get(`/api/perfil`).then(response => {
                    this.usuario = response.data
                })
            },
            update() {
                this.$http.put(`/api/perfil`, this.usuario).then(response => {
                    this.$store.commit('SET_SNACKBAR', { text: "Transacción exitosa", color: "success" })
                    this.$router.push({ path: "/dashboard" })
                });
            }
        }
    }
</script>